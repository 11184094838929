<script>
import store from '@/infrastructure/store'
import MyItemsList from '../components/MyItemsList'

export default {
  components: {
    MyItemsList,
  },

  data() {
    return {
      isLoadingItems: false,
    }
  },

  computed: {
    items: () => store.getters['items/myArchivedItems'],
  },

  created() {
    this.loadItems()
  },

  methods: {
    async loadItems() {
      this.isLoadingItems = true
      try {
        await store.dispatch('items/fetchMyArchivedItems')
      } finally {
        this.isLoadingItems = false
      }
    },
  },
}
</script>

<template>
  <div>
    <v-card :loading="isLoadingItems">
      <v-card-title>
        <div class="mx-auto text-center">My archived items</div>
      </v-card-title>
    </v-card>

    <div class="pt-3"></div>

    <MyItemsList :items="items" item-css-class="mb-3" />
  </div>
</template>
